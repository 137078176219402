<template>
    <v-card flat style="max-width: 800px">
        <v-card-text>
            <v-data-table :headers="deltakerFelter" :items="deltakere" no-data-text="Ingen deltakere." :items-per-page="-1" must-sort hide-default-footer>
                <template v-slot:header.rediger>
                    <a
                        v-on:click="toggleParticipants"
                        v-text="
                            type == 'SORGGRUPPE' ? (visAvmeldte ? 'Skjul avsluttete' : 'Vis avsluttete') : visAvmeldte ? 'Skjul meldt frafall' : 'Vis meldt frafall'
                        "
                    ></a>
                </template>
                <template v-slot:item.deltatt="{ item }">
                    <v-checkbox v-if="item.person.fulltNavn && !item.fjernet" v-model="item.deltatt" v-on:click="participated(item, item.deltatt)"></v-checkbox>
                </template>
                <template v-slot:item.fulltNavn="{ item }">
                    <span v-html="item.person.fulltNavn || 'Slettet bruker'" v-bind:style="{ textDecoration: item.fjernet ? 'line-through' : 'none' }"></span>
                </template>
                <template v-slot:item.lagtTil="{ item }">
                    <span v-html="item.lagtTil.format('DD.MM.YYYY')"></span>
                </template>
                <template v-slot:item.rediger="{ item }">
                    <span v-if="item.fjernet" v-html="item.fjernet.format('DD.MM.YYYY')"></span>
                    <div v-else class="d-flex justify-center">
                        <v-icon v-if="!item.fjernet" small v-on:click="removeParticipant(item)">mdi-delete</v-icon>
                    </div>
                </template>
                <template v-slot:foot>
                    <tfoot v-if="alleDeltakere.length">
                        <tr>
                            <td colspan="4" style="height: auto">
                                <div class="pt-4 d-flex align-center">
                                    <v-autocomplete
                                        label="Velg deltaker"
                                        v-model="nyDeltaker"
                                        :items="alleDeltakere"
                                        item-text="fulltNavn"
                                        item-value="id"
                                        no-data-text="Ingen flere deltakere"
                                        hide-details
                                    ></v-autocomplete>
                                    <v-btn outlined class="mt-4 ml-2" v-on:click="addParticipant"> Legg til </v-btn>
                                </div>
                            </td>
                        </tr>
                    </tfoot>
                </template>
            </v-data-table>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'ActivityTabParticipants',

    props: {
        value: {
            type: Object,
        },
        type: {
            type: String,
        },
    },
    data() {
        return {
            aktivitet: {},

            visAvmeldte: false,
            deltakere: [],

            nyDeltaker: '',
            alleDeltakere: [],

            deltakerFelter: [
                { text: 'Deltatt', value: 'deltatt', sortable: false, width: '70px' },
                { text: 'Navn', value: 'fulltNavn', sortable: false },
                { text: 'Påmeldt', value: 'lagtTil', sortable: true, width: '150px' },
                { text: '', value: 'rediger', sortable: false, width: '130px' },
            ],
        };
    },
    computed: {
        ...mapGetters('api', ['hasRole']),
    },

    /**
     * created
     */
    created: async function () {
        this.aktivitet = this.value;
        await this.update();
    },

    methods: {
        ...mapActions('api', ['request', 'formatActivity', 'formatPerson']),
        ...mapActions(['snackbar']),

        /**
         * update
         */
        update: async function (aktivitet) {
            if (aktivitet) {
                this.aktivitet = await this.formatActivity(aktivitet);
                this.$emit('input', aktivitet);
            }

            const deltakerIds = [];
            this.deltakere = [];
            if (this.aktivitet) {
                if (this.aktivitet.deltakere) {
                    for (const deltaker of this.aktivitet.deltakere) {
                        if (this.visAvmeldte || !deltaker.fjernet) {
                            this.deltakere.push(deltaker);
                            deltakerIds.push(deltaker.person.id);
                        }
                    }
                }
            }

            if (this.type == 'SORGGRUPPE') {
                if (this.hasRole('ANSATT')) {
                    const response = await this.request({
                        method: 'get',
                        url: '/sorggruppe/' + this.aktivitet.referanseId,
                    });

                    if (response && typeof response.id != 'undefined') {
                        this.alleDeltakere = [];
                        for (const deltaker of response.deltakere) {
                            if (!deltaker.fjernet && !deltakerIds.find((id) => id == deltaker.person.id)) {
                                this.alleDeltakere.push(await this.formatPerson(deltaker.person));
                            }
                        }
                    }
                }
            } else {
                // personer
                let response = await this.request({
                    method: 'get',
                    url: '/person/',
                });
                if (response && typeof Array.isArray(response)) {
                    this.alleDeltakere = [];
                    for (const person of response) {
                        this.alleDeltakere.push(await this.formatPerson(person));
                    }
                }
            }
        },

        /**
         * toggleParticipants
         */
        toggleParticipants: function () {
            this.visAvmeldte = !this.visAvmeldte;
            this.update();
        },

        /**
         * addParticipant
         */
        addParticipant: async function () {
            if (!this.nyDeltaker) {
                console.log('TODO');
            } else {
                const data = new FormData();
                data.append('personId', [this.nyDeltaker]);

                const response = await this.request({
                    method: 'post',
                    url: '/aktivitet/' + this.aktivitet.id + '/deltaker',
                    data,
                });
                if (!response) {
                    console.log('TODO: error');
                } else {
                    this.update(response);
                    this.nyDeltaker = null;
                }
            }
        },

        /**
         * removeParticipant
         */
        removeParticipant: async function (item) {
            const data = new FormData();
            data.append('personId', [item.person.id]);

            const response = await this.request({
                method: 'delete',
                url: '/aktivitet/' + this.aktivitet.id + '/deltaker',
                data,
            });
            if (!response) {
                console.log('TODO: error');
            } else {
                this.update(response);
            }
        },

        /**
         * participated
         */
        participated: async function (item, participated) {
            const data = new FormData();
            data.append('personId', [item.person.id]);

            const response = await this.request({
                method: participated ? 'post' : 'delete',
                url: '/aktivitet/' + this.aktivitet.id + '/deltakerDeltatt',
                data,
            });
            if (!response) {
                console.log('TODO: error');
            } else {
                this.update(response);
            }
        },
    },
};
</script>
